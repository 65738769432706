var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"367aab605e4f8221b9183590dcf58702565a4544"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";
import { CONTEXT } from "./src/config.ts";

Sentry.init({
  dsn: "https://6a1d45b62ba34ae4ba96ff99ad5ddd22@o75091.ingest.sentry.io/211416",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: CONTEXT,
  enabled: CONTEXT !== "development",
  replaysOnErrorSampleRate: 1.0, // always record replay on error
  integrations: [
    new Sentry.Replay({
      blockSelector: '[data-fs="hide"]',
      maskAllText: false,
    }),
    new posthog.SentryIntegration(posthog, "perdoo", "211416"),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
